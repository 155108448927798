<template>
	<article-index-page type="blog" :item-link="{to: $web('my.profile.manage.articles.create'), text: $t('action.write_blog_article'), button: true, color: $config.public.colors.success}"/>
</template>

<script setup lang="ts">
const {t} = useI18n();

useMeta({
	title: t('view.blog.index.title'),
	description: t('view.blog.index.description'),
	canonicalQueries: ['search', 'flavor', 'sport', 'category', 'club']
});

</script>

